<div class="alt-text-container">
  <div>
    <p>
      Alternative text is mandatory for all figures that are not ‘illustrative only’ in order to meet accessibility
      requirements. A figure is ‘illustrative only’ if it only visually shows, and does not introduce anything
      additional, to what is in the associated clause or note.
    </p>
    <p>Guidance: How would you describe this object and its context to someone who is blind or low vision?</p>
    <ul>
      <li>The subject(s) in detail</li>
      <li>The setting</li>
      <li>The actions or interactions</li>
      <li>Other relevant information</li>
    </ul>
    <p>
      <i>(1-2 detailed sentences recommended)</i>
    </p>
  </div>
  <div class="alt-image" *ngFor="let image of imageFragments">
    <cars-alt-image [imageFragment]="image"></cars-alt-image>
    <mat-checkbox
      #illustrativeFigure
      *ngIf="showIllustrativeFigureCheckbox(image)"
      (change)="setIllustrativeFigureProperties(image, $event.checked)"
      [checked]="image.isIllustrativeFigure"
      [disabled]="
        (CarsAction.AUTHOR_DOCUMENT | carsDisabled : image.documentId | async) || image.virusScanState !== 'OK'
      "
      >Figure is illustrative only</mat-checkbox
    >
  </div>
</div>

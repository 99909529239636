import {DocumentChanges} from 'app/documents/document-changes';
import {CategoryOfChange} from 'app/fragment/types/category-of-change';
import {DisciplineDMRB} from './enum-select-input/discipline';
import {LifecycleStageDMRB} from './enum-select-input/lifecycle-stage-dmrb';
import {LifecycleStageMMHW} from './enum-select-input/lifecycle-stage-mmhw';

const ENUM_NAME_TO_ENUM_LOOKUP: Readonly<Record<string, any>> = {
  DisciplineDMRB: DisciplineDMRB,
  LifecycleStageDMRB: LifecycleStageDMRB,
  LifecycleStageMMHW: LifecycleStageMMHW,
  DocumentChanges: DocumentChanges,
  CategoryOfChange: CategoryOfChange,
};

export function getEnumByName(name: string): any {
  return ENUM_NAME_TO_ENUM_LOOKUP[name];
}
